<template>
  <v-form>
    <loading :active.sync="loading"></loading>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <span
          style="
            font-family: 'IBM Plex Sans Thai';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 40px;
          "
        >
          จัดการรายวิชา
        </span>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-spacer></v-spacer>
          <v-col class="text-right">
            <v-btn @click="goToCreateMajorSub()" color="#2AB3A3" dark
              >เพิ่มรายวิชาใหม่</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card
          ><v-data-table
            :headers="headersMajorSub"
            :items="itemsMajorSub"
            :search="search"
            class="elevation-1"
          >
            <template v-slot:[`item.img_url`]="{ item }">
              <v-img :src="item.img_url" width="120px" class="ma-1"></v-img>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-icon @click="ViewNews(item)">mdi-content-copy</v-icon>
              <v-icon @click="UpdateNews(item)">mdi-pencil</v-icon>
              <v-icon @click="DeleteItemMajorSub(item)">mdi-delete</v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import draggable from "vuedraggable";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Decode, Encode } from "@/services";
import moment from "moment";
export default {
  components: {
    draggable,
    Loading,
  },
  data() {
    return {
      // "id": 6,
      //       "name_en": "name_en",
      //       "name_th": "name_th",
      //       "createdAt": "2022-08-11T03:59:01.000Z",
      //       "updatedAt": "2022-08-11T03:59:01.000Z",
      //       "levelId": null,
      //       "level": null
      headersMajorSub: [
        { text: "ลำดับ", value: "sortindex", align: "start" },
        { text: "ชื่อรายวิชา", value: "name", align: "start" },
        { text: "รายละเอียด", value: "description", align: "start" },
        { text: "สาขาวิชาเอก (ไทย)", value: "major.name_th", align: "start" },
        { text: "ปุ่มจัดการ", value: "action", align: "center" },
      ],
      dialog: false,
      isEdit: false,
      itemsMajorSub: [],
      search: "",
      sortindex: 0,
      user: "",
      loading: false,
      imgUrl: "",
    };
  },
  computed: {
    isWindowWidthGreaterThan768() {
      return window.innerWidth > 768;
    },
  },
  created() {
    this.user = JSON.parse(Decode.decode(localStorage.getItem("gscmAdmin")));
    this.getAllMajorSub();
  },
  methods: {
    convertDate(val) {
      return moment(val).format("MM/DD/YYYY hh:mm");
    },
    async DragItems(val) {
      this.loading = true;
      console.log("this.itemsssssss", this.itemsMajorSub);
      console.log("beforeAPI", val);
      var user = JSON.parse(Decode.decode(localStorage.getItem("gscmAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      // const response = await this.axios.put(
      //   `${process.env.VUE_APP_API}/knowledges/updateIndex?news_type=ข่าวประชาสัมพันธ์`,
      //   val,
      //   auth
      // );
      console.log("afterAPI", response);

      this.itemsMajorSub = response.data.data;
      // for (let i in this.itemsMajorSub) {
      //   this.itemsMajorSub[i].count = parseInt(i) + 1;
      // }
      this.loading = false;
    },
    async getAllMajorSub() {
      this.loading = true;
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/coursesOffereds`,
        auth
        // 'http://localhost:8081/types/'
      );
      console.log("getAllMajorSub", response.data.data);
      this.itemsMajorSub = response.data.data;
      // this.imgUrl = response.data.data.imgUrl;
      for (let i in this.itemsMajorSub) {
        this.itemsMajorSub[i].sortindex = parseInt(i) + 1;
      }
      this.loading = false;
    },
    async DeleteItemMajorSub(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        if (result.isConfirmed) {
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/coursesOffereds/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllMajorSub();
        }
      });
    },
    goToCreateMajorSub() {
      this.$router.push("CreateMajorSub");
    },
    UpdateMajorSub(val) {
      localStorage.setItem("MajorSubData", Encode.encode(val));
      this.$router.push("EditMajorSub");
    },
    ViewMajorSub(val) {
      localStorage.setItem("MajorSubData", Encode.encode(val));
      this.$router.push("ViewMajorSub");
    },
  },
};
</script>